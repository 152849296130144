import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout" 
import { FaTwitter, FaLinkedin, FaFacebook } from "react-icons/fa"
import logoNemo from "../images/index/nemo.png"
import logoPoc from "../images/index/poc.png"

const HomePage = ({ data }) => {

  return (
    <>
      <Layout background="true">          

          <div className="text-wrapper">
            <h2>DANSE + NUMÉRIQUE</h2>
            <p>
              Après avoir étudié à 42 et HEC, apprentis pour Pietragalla & Derouault, Aloïs LECLET fonde la compagnie Bêta afin de créer et questionner autour de la danse et du numérique.
            </p>
          
            <Link className="button" to="/creations">Créations</Link>
          </div>

          <div className="logo-wrapper">
            <img src={logoPoc} />
            <img src={logoNemo} />
          </div>

      </Layout>
    </>
  )
}

export default HomePage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
